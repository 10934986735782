@use 'ui-kit/theme/units' as u;
@use 'ui-kit/theme/media' as mq;

.root {
  padding: u.spacing(8, 0);
  margin-top: u.spacing(8);
  background-color: var(--c-gray300);

  @include mq.forUpToTablet {
    padding: u.spacing(6, 0, 5);
    margin-top: u.spacing(3);
  }
}

.headerWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: u.spacing(6);

  @include mq.forUpToTablet {
    margin-bottom: u.spacing(3);
  }
}

.paper {
  padding: u.spacing(2);
  border-radius: 8px;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  background-color: var(--c-white);
  border: 1px solid var(--c-gray500);
  display: flex;
  align-items: center;
  cursor: pointer;

  @include mq.forUpToTablet {
    padding: u.spacing(1.5);
  }
}

.icon {
  height: 72px;

  & span {
    width: 72px !important;
    height: 72px !important;
  }

  @include mq.forUpToTablet {
    height: 48px;

    & span {
      width: 48px !important;
      height: 48px !important;
    }
  }
}

.image {
  border-radius: 100px;
  flex-shrink: 0;
}

.content {
  width: 100%;
}

.city {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.button {
  max-height: 48px;
  min-width: 180px;

  @include mq.forUpToTabletLandscape {
    grid-column: 1 / -1;
  }
}

.cityLink {
  padding: u.spacing(2);
  border-radius: 8px;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  background-color: var(--c-white);
  border: 1px solid var(--c-gray500);
  display: flex;
  gap: u.spacing(2);
  align-items: center;
  cursor: pointer;

  @include mq.forUpToTablet {
    padding: u.spacing(1.5);
    gap: u.spacing(1.5);
  }
}

.contentWrapper {
  display: grid;
  grid-template-columns: 1fr;
  gap: u.spacing(2);

  @include mq.forDesktopSmallUp {
    grid-template-columns: repeat(2, 1fr);
  }

  @include mq.forDesktopMidUp {
    grid-template-columns: repeat(3, 1fr);
    gap: u.spacing(4);
  }
}
