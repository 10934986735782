@use 'ui-kit/theme/units' as u;
@use 'ui-kit/theme/media' as mq;

.root {
  background-color: var(--c-white);
}

.divider {
  margin-top: u.spacing(5);
}

.hidden {
  display: none;
}
