@use 'ui-kit/theme/units' as u;

.isPrimary {
  border-radius: 8px;
  color: var(--c-black);
  & :global(.ps-svg-icon--dynamic) {
    & path {
      stroke: var(--c-black);
    }
  }
}

.isFavorite {
  & :global(.ps-svg-icon--dynamic) {
    fill: var(--c-secondaryRed);
    & path {
      stroke: var(--c-secondaryRed);
    }
  }
}

.root {
  background-color: var(--c-white);
  border: 1px solid var(--c-gray600);
  &:not(.isFavorite) {
    & :global(.ps-svg-icon--dynamic) {
      & path {
        stroke: var(--c-primaryBlue);
      }
    }
  }
  &:hover {
    background-color: var(--c-white);
    &:not(.isFavorite) {
      & :global(.ps-svg-icon--dynamic) {
        fill: var(--c-inactiveLightRed);
        & path {
          stroke: var(--c-secondaryRed);
        }
      }
    }
  }
}

.noBorder {
  border: none;
}

.iconWrapper {
  display: flex;
  align-items: center;
}

.isSm {
  padding: u.spacing(1);
}
