@use 'ui-kit/theme/units' as u;
@use 'ui-kit/theme/media' as mq;

.iterableHorizontalCard {
  display: flex;
  padding: u.spacing(3);
  flex-flow: row wrap;
  gap: u.spacing(3);
  align-items: center;
  border-radius: u.spacing(1);
  background: linear-gradient(-90deg, var(--c-marketingPrimaryBlue), #004880);
  @include mq.forPhone() {
    flex-direction: column;
    align-items: flex-start;
  }
}

.contentWrapper {
  display: flex;
  flex: 1;
  align-items: center;
  gap: u.spacing(2);
  @include mq.forPhone() {
    flex-direction: row-reverse;
    width: 100%;
  }
}

.iconContainer {
  $padding: u.spacing(1.5);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: u.spacing(1.5);
  flex: 0 0 auto;
  background-color: white;
  clip-path: circle(50%);
  @include mq.forPhone() {
    display: flex;
    width: calc(40px + #{$padding});
    height: calc(40px + #{$padding});
  }
}

.content {
  flex: 1 1 auto;
}

.buttonContainer {
  display: flex;
  gap: u.spacing(1);
  @include mq.forDesktopSmallUp() {
    flex: 0 1 auto;
    justify-content: flex-end;
  }
}

.buttonContainer :global(.MuiButton-root) {
  min-width: 140px;
}

.buttonContainer :global(.MuiButton-root:only-child) {
  min-width: 180px;
}
