@use 'ui-kit/theme/units' as u;
@use 'ui-kit/theme/media' as mq;

.root {
  background: var(--c-primaryBlue);
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include mq.forDesktopSmallUp {
    flex-direction: row;
    padding: u.spacing(6);
    gap: u.spacing(2);
  }

  @include mq.forDesktopMidUp {
    gap: u.spacing(9);
  }
}

.imageWrapper {
  width: 264px;
  height: 241px;
  @include mq.forDesktopSmallUp {
    width: 544px;
    height: 497px;
  }
}

.houseImageCell {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: u.spacing(3, 3, 6, 3);
}

.items {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 448px;
  flex-direction: column;
  padding: u.spacing(6, 0, 0);
  @include mq.forDesktopSmallUp {
    justify-content: left;
    align-items: flex-start;
  }
}

.contentWrapper {
  height: 100%;
}

.description {
  margin: u.spacing(3, 0);
}

.button {
  width: 100%;
  @include mq.forDesktopSmallUp {
    width: 180px;
  }
}
