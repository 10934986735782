@use 'ui-kit/theme/units' as u;
@use 'ui-kit/theme/media' as mq;

.root {
  text-align: center;
  margin-bottom: u.spacing(8);
  margin-top: u.spacing(10);

  @include mq.forUpToTablet {
    margin-bottom: u.spacing(6);
    margin-top: u.spacing(5);
  }
}

.pressBrands {
  margin-top: u.spacing(1);

  @include mq.forUpToTablet {
    margin-top: u.spacing(2);
  }
}
