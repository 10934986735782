.root {
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  text-transform: none;
  min-width: auto;
  border-radius: 4px;
  border: 1px solid var(--c-gray700);
  border-color: var(--c-gray600);
  color: var(--toggle-button-color);
  background-color: transparent;
  stroke: var(--c-primaryBlue);
  &:global(.Mui-selected),
  &:global(.Mui-selected):hover {
    border: 1px solid var(--toggle-button-selected-color);
    color: var(--toggle-button-color);
    background-color: var(--toggle-button-hover-background-color);
    &:hover {
      background-color: var(--toggle-button-hover-background-color);
      cursor: default;
    }
  }
  &::before {
    content: var(--toggle-button-tooltip-content);
    position: absolute;
    top: 0;
    right: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 8px;
    border-right-color: var(--c-primaryYellow);
    border-top-color: var(--c-primaryYellow);
  }
}

.fullWidth {
  width: 100%;
}
