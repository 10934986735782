.link {
  text-decoration: none;
  font-weight: bold;
  &:hover {
    text-decoration: underline;
  }
}

.linkUnderlined {
  text-decoration: underline;
}

.text {
}
