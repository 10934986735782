@use 'ui-kit/theme/units' as u;

.helperText {
  margin: 0;
  display: flex;
  align-items: center;
  gap: u.spacing(0.5);
}

.errorIcon {
  min-width: 16px;
}

.errorText {
  margin-top: 0;
}
