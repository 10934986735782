@use 'ui-kit/theme/units' as u;
@use 'ui-kit/theme/media' as mq;

.gridRoot {
  margin-bottom: u.spacing(1);
  margin-top: 8px !important;
}

.form {
  width: 100%;
}

.errorMessage {
  color: var(--c-inactiveRed);
}

.box {
  margin-bottom: 30px;
}

.iconWrapper {
  margin-bottom: 20px;
  & svg {
    @include mq.forUpToTabletLandscape {
      width: 64px;
      height: 64px;
    }
  }
}

.bannerIcon {
  width: 20px;
}

.banner {
  margin-top: u.spacing(3);
  margin-bottom: u.spacing(3);
  @include mq.forUpToTabletLandscape {
    margin-top: u.spacing(2);
    margin-bottom: u.spacing(2);
  }
}

.newBanner {
  background: var(--c-activeLightGreen);
  border: 1px solid var(--c-activeGreen);
}

.title {
  margin-bottom: u.spacing(2);
}

.textContainer {
  margin-top: u.spacing(3);
  @include mq.forUpToTabletLandscape {
    margin-bottom: u.spacing(2);
  }
}
