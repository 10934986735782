@use 'ui-kit/theme/units' as u;
@use 'ui-kit/theme/media' as mq;

.root {
  display: flex;
  gap: u.spacing(1);
  flex-direction: column;
  @include mq.forTabletUp {
    gap: u.spacing(3);
    flex-direction: row;
  }
}

.item {
  display: flex;
  align-items: center;
  gap: u.spacing(0.5);
}
