@use 'ui-kit/theme/media' as mq;
@use 'ui-kit/theme/layers' as z;

.root {
  width: 100%;
  height: 100%;
}

.swiper {
  height: 100%;
  width: 100%;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.5) -14.38%, rgba(0, 0, 0, 0) 32.36%),
      linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
    pointer-events: none;
    z-index: z.$sliderImage;
  }
}
