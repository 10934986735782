@use 'ui-kit/theme/units' as u;
@use 'ui-kit/theme/media' as mq;
@use 'ui-kit/theme/layers' as z;

.root {
  position: relative;
  height: 364px !important;
  width: 100%;
  @include mq.forTabletUp {
    height: 617px !important;
  }
}

.layout {
  display: flex;
  flex-direction: column;
  gap: u.spacing(3);
  padding-inline: u.spacing(3);

  @include mq.forTabletLandscape {
    padding-inline: u.spacing(5);
  }

  @include mq.forDesktopMidUp {
    padding-inline: u.spacing(10);
  }

  inset: 0;
  position: absolute;
  z-index: z.$sliderImage + 1;
  pointer-events: none;

  @include mq.forPhone {
    padding-block: u.spacing(3);
    align-items: flex-start;
  }
  @include mq.forTabletUp {
    top: 28%;
  }
}

.searchWrapper {
  pointer-events: auto;
  left: 24px;
  right: 24px;
  z-index: z.$sliderImage + 1;
  @include mq.forPhone {
    position: absolute;
    top: 338px;
  }
  @include mq.forTabletUp {
    max-width: 544px;
  }
}

.sweepstakesBanner {
  z-index: z.$sliderImage + 1;
  box-sizing: border-box;
  width: 100%;
  min-height: 60px;
  @include mq.forTabletUp {
    position: absolute;
    left: 0;
    top: 0;
  }
}

.textWrapper {
  max-width: 664px;
  margin-top: u.spacing(2);
}
