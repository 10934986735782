@use 'ui-kit/theme/units' as u;
@use 'ui-kit/theme/colors' as c;
@use 'ui-kit/theme/media' as mq;

.root {
  padding: u.spacing(8, 0, 0);
  background-color: var(--c-white);
  text-align: left;
  @include mq.forUpToTablet {
    padding: u.spacing(6, 0);
  }
}

.header {
  min-height: 110px;
  display: flex;
  text-align: left;
  flex-direction: row;
  align-items: center;
  padding: 0;
  justify-content: space-between;
  margin-bottom: u.spacing(4);
  @include mq.forUpToTablet {
    display: block;
  }
}

.headerText {
  flex: none;
  order: 0;
  flex-grow: 1;
}

.pressFlex {
  display: flex;
  gap: u.spacing(4);
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-height: 80px;
  @include mq.forUpToTablet {
    flex-direction: column;
    gap: u.spacing(4);
    align-items: center;
    max-height: 100%;
  }
}

.linkWrapper {
  & img {
    max-height: 80px;
    @include mq.forUpToTablet {
      height: 22px;
    }
  }
}

.awardsGrid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: u.spacing(4);
  text-align: center;
  @include mq.forTablet {
    grid-template-columns: repeat(3, 1fr);
  }
  @include mq.forDesktopSmallUp {
    grid-template-columns: repeat(5, 1fr);
  }
}

.content {
  margin-top: u.spacing(8);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon {
  margin-bottom: u.spacing(3);
  max-width: 100%;
}

.averageRating {
  padding: u.spacing(1, 2);
  width: 247px;
  height: 44px;
  background: var(--c-white);
  border: 1px solid var(--c-gray600);
  border-radius: 100px;
  text-transform: lowercase;
  font-size: 18px;
  color: var(--c-black);
  display: flex;
  gap: u.spacing(0.5);

  &:hover {
    background-color: var(--c-white);
  }
  @include mq.forUpToTablet {
    margin: u.spacing(1);
    margin-left: 0;
  }
}

.rating {
  color: #ecbc3e;
}

.ratingEmpty {
  width: 21px;
}

.trustPilot {
  display: flex;
  justify-content: center;
}

.slider {
  margin-left: u.spacing(-2);
  margin-right: u.spacing(-2);
}

.stepperRoot {
  flex: 1;
  justify-content: center;
  padding-top: u.spacing(1);
  padding-bottom: u.spacing(5);
  background-color: var(--c-white);
}

.stepperDotActive {
  background-color: var(--c-primaryBlue) !important;
}

.stepperDot {
  margin: u.spacing(0.5);
  background-color: fade-out(c.$primaryBlue, 0.4);
}

.title {
  margin-top: u.spacing(4);
}

.widget {
  iframe {
    margin: auto;
  }
}
