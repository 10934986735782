@use 'ui-kit/theme/units' as u;

.root {
  display: flex;
  align-items: center;
  gap: u.spacing(0.5);
}

.link {
  &:hover {
    cursor: pointer;
  }
}
