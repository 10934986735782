@use 'ui-kit/theme/units' as u;
@use 'ui-kit/theme/media' as mq;

.root {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  @include mq.forUpToTablet {
    flex-flow: column-reverse;
    max-width: 433px;
    margin-left: auto;
    margin-right: auto;
  }

  margin-bottom: u.spacing(5);
  margin-top: u.spacing(8);

  @include mq.forUpToTablet {
    margin-top: 0;
  }
}

.box {
  border: 1px solid var(--c-gray600);
  display: flex;
  flex-direction: column;
  gap: u.spacing(3);
  padding: u.spacing(3);
  border-bottom-left-radius: 8px;
  @include mq.forUpToTablet {
    border-bottom-right-radius: 8px;
  }
  @include mq.forDesktopSmallUp {
    padding: u.spacing(5);
    width: 64%;
    border-top-left-radius: 8px;
  }
}

.list {
  display: flex;
  flex-direction: column;
  gap: u.spacing(2);
}

.listItem {
  display: flex;
  align-items: center;
  gap: u.spacing(1);
}

.imageWrapper {
  overflow: hidden;
  border: 1px solid var(--c-gray600);
  position: relative;
  height: 312px;
  width: 100%;
  border-top-right-radius: 8px;
  border-left: 0;
  @include mq.forUpToTablet {
    border-top-left-radius: 8px;
  }
  @include mq.forDesktopSmallUp {
    height: 363px;
    width: 50%;
    border-bottom-right-radius: 8px;
  }
  @include mq.forDesktopMidUp {
    max-width: 443px;
    height: 332px;
  }
}

.button {
  @include mq.forDesktopSmallUp {
    max-width: 211px;
  }
}
