@use 'ui-kit/theme/units' as u;
@use 'ui-kit/theme/media' as mq;

.paper {
  display: flex;
  flex-direction: column;
  gap: u.spacing(2);
  padding: u.spacing(4);
  background-color: var(--c-white);
  border: 1px solid var(--c-gray400);
  box-sizing: border-box;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  min-height: 288px;
  @include mq.forUpToTablet {
    height: 216px;
    padding: u.spacing(3);
    grid-gap: u.spacing(1);
  }
}

.fromSearchPage {
  @include mq.forUpToTablet {
    height: auto;
  }
}
