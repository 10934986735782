@use 'ui-kit/theme/layers' as z;

.root {
  display: block;
  position: fixed;
  top: 62px;
  bottom: auto;
  left: 0;
  right: 0;
  z-index: z.$appBar - 1;
  background-color: var(--c-primaryBlue);
}
