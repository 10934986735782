@use 'ui-kit/theme/units' as u;
@use 'ui-kit/theme/media' as mq;
@use 'ui-kit/theme/colors' as c;

.root {
  max-width: 1280px;
  padding-top: u.spacing(8);
  @include mq.forUpToTablet {
    padding-top: u.spacing(1);
  }
}

.divider {
  margin-top: u.spacing(5);
  @include mq.forUpToTablet {
    margin-top: u.spacing(6);
  }
}

.slider {
  padding-top: u.spacing(3);
}

.stepperRoot {
  flex: 1;
  justify-content: center;
  padding-top: u.spacing(1);
  padding-bottom: u.spacing(5);
  background-color: var(--c-white);
}

.stepperDotActive {
  background-color: var(--c-primaryBlue) !important;
}

.stepperDot {
  margin: u.spacing(0.5);
  background-color: fade-out(c.$primaryBlue, 0.4);
}

.buttonsWrapper {
  display: flex;
  gap: u.spacing(2);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: u.spacing(4);
}
