@use 'ui-kit/theme/units' as u;
@use 'ui-kit/theme/media' as mq;

.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: u.spacing(2);
  @include mq.forUpToTablet {
    flex-wrap: wrap;
    gap: u.spacing(4);
  }
}

.isPrimary {
  @include mq.forUpToTablet {
    flex-direction: column;
  }
}

.link {
  order: 1;
}

.isSecondary {
  @include mq.forUpToTablet {
    flex-wrap: wrap;
    justify-content: center;
  }
}

// This classnames updates the size of each brand icon for certain breakpoints, this is to avoid CLS.
.icon {
  --size-ratio: 1;
  min-width: calc(var(--icon-min-width) / var(--size-ratio));
  min-height: calc(var(--icon-min-height) / var(--size-ratio));

  @include mq.forUpToTabletLandscape {
    --size-ratio: 1.25;
  }

  &.newYorkTimes {
    --icon-min-width: 185px;
    --icon-min-height: 25px;
  }

  &.forbes {
    --icon-min-width: 93px;
    --icon-min-height: 25px;
  }

  &.wallStreetJournal {
    --icon-min-width: 277px;
    --icon-min-height: 24px;
  }

  &.techCrunch {
    --icon-min-width: 48px;
    --icon-min-height: 32px;
  }

  &.coStar {
    --icon-min-width: 160px;
    --icon-min-height: 28px;
  }

  &.fastCo {
    --icon-min-width: 160px;
    --icon-min-height: 24px;
  }

  &.inc {
    --icon-min-width: 55px;
    --icon-min-height: 34px;
  }
}
