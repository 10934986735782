@use 'ui-kit/theme/units' as u;
@use 'ui-kit/theme/media' as mq;

.root {
  margin-top: u.spacing(8);
  margin-bottom: u.spacing(8);
  @include mq.forUpToTablet {
    margin-top: u.spacing(6);
    margin-bottom: u.spacing(6);
  }
  display: flex;
  flex-direction: column;
  gap: u.spacing(2);
}

.fromSearchPage {
  margin-bottom: 0;
  @include mq.forUpToTablet {
    margin: u.spacing(5, 0);
  }
}

.container {
  margin: u.spacing(4, -4, 0);
  @include mq.forUpToTablet {
    margin: u.spacing(3, -2, 0);
  }
}

.buttonState {
  &:hover {
    background-color: var(--c-white);
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
