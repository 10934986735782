@use 'ui-kit/theme/units' as u;
@use 'ui-kit/theme/media' as mq;

.paper {
  background: linear-gradient(250.69deg, #0077b6 0%, #00457a 100%);
  border-radius: 8px;
}

.root {
}

.content {
  padding: u.spacing(0, 3);
}

.justify {
  display: flex;
  margin: u.spacing(0, -1);
  @include mq.forDesktopSmallUp {
    justify-content: center;
  }
}

.justifyItem {
  @include mq.forUpToTablet {
    flex: 1;
    padding: u.spacing(0, 1);
  }
  @include mq.forDesktopSmallUp {
    &:last-child {
      display: none;
    }
  }
}

.button {
  @include mq.forUpToTablet {
    width: 100%;
  }
  @include mq.forDesktopSmallUp {
    width: 228px;
  }
}

.dialogPaper {
  padding: 0;
}

.dialogContainer {
  margin: 0;
  padding: 0;
  background-color: var(--c-primaryBlue);
}

.closeButtonWrapper {
  position: absolute;
  right: 24px;
  top: 24px;
}
