@use 'ui-kit/theme/units' as u;
@use 'ui-kit/theme/media' as mq;

.root {
}

.container {
  padding: u.spacing(3, 0);
  border-bottom: 1px solid var(--c-gray600);
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
}

.itemHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
}

.itemContent {
  margin-top: u.spacing(3);
}

.active {
}

.withVariant {
  padding: u.spacing(3, 4);
  border-bottom: none;
  border-radius: 8px;
}

.borderedVariant {
  border: 1px solid var(--c-gray600);
  & + & {
    margin-top: u.spacing(3);
  }
}

.filledVariant {
  @include mq.forUpToTablet {
    padding: u.spacing(2);
  }
  &:hover {
    background-color: var(--c-gray400);
  }
  & .active {
    background-color: var(--c-gray400);
  }
}
.iconWrapper {
  display: flex;
  justify-content: flex-end;
}
