@use 'ui-kit/theme/units' as u;
@use 'ui-kit/theme/media' as mq;

.root {
  padding: u.spacing(3);
  height: 210px;
  border-radius: 8px;
  background-color: var(--c-white);
  border: 1px solid var(--c-gray400);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.06);
  @include mq.forUpToTablet {
    height: 206px;
  }
}

.ratingEmpty {
  width: 21px;
}

.cardTitle {
  display: flex;
  flex-wrap: nowrap;
  margin: u.spacing(1, 0);
  gap: u.spacing(1);
}

.cardContent {
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  font-weight: normal;
  max-height: 145px;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  @include mq.forUpToTablet {
    -webkit-line-clamp: 6;
  }
}

.cardRating {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: u.spacing(1);
}

.iconCard {
  justify-content: space-between;
}
