@use 'ui-kit/theme/units' as u;
@use 'ui-kit/theme/media' as mq;

.root {
  margin-top: u.spacing(4);
}

.wrapper {
  position: relative;
  border-radius: 12px;
  background-image: url('./assets/desktopBg.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 132px;
  margin-top: u.spacing(8);
}

.imageWrapper1 {
  position: absolute;
  left: 6%;
  top: 14%;
  @include mq.forUpToTabletLandscape {
    left: 0;
    transform: scale(0.85);
  }
  @include mq.forDesktopSmall {
    right: 57%;
    transform: scale(0.9);
    left: unset;
  }
  @include mq.forUpToTablet {
    left: 0;
    transform: scale(0.9);
  }
}

.imageWrapper2 {
  position: absolute;
  right: 33%;
  top: 32%;
}

.imageWrapper3 {
  position: absolute;
  right: 7%;
  top: 19%;
  @include mq.forDesktopSmall {
    right: 1%;
  }
}

.mobileBannerWrapper {
  text-align: center;
}
