@use 'ui-kit/theme/units' as u;
@use 'ui-kit/theme/media' as mq;

.root {
}

.inputs {
  margin: u.spacing(3, 0);
  @include mq.forUpToTabletLandscape {
    margin: u.spacing(2, 0);
  }
}

.buttons {
  margin-top: u.spacing(2);
}
