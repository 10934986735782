@use 'ui-kit/theme/units' as u;
@use 'ui-kit/theme/media' as mq;

.slide {
  &:global(.swiper-slide) {
    height: 100%;
    box-sizing: border-box;
    width: 316px;
    &.compactSlide {
      width: 250px;
    }
  }
}

.outsidePadded {
  position: relative;
  width: 100%;
  box-sizing: content-box;
  @include mq.forDesktopSmallUp {
    right: u.spacing(8);
  }
}

.cardContainer {
  max-width: 316px;
}

.favorite {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: u.spacing(0.75);
  border-radius: 4px;
}

.skeleton {
  transform: none;
  width: 315px;
  flex-shrink: 0;

  @include mq.forPhone {
    width: 100%;
  }
}
