@use 'ui-kit/theme/units' as u;

.root {
  width: 100%;
}

.inputRoot {
  height: 48px;
  & fieldset {
    border-radius: 4px;
    border-width: 2px;
    border-style: solid;
    border-color: var(--c-gray600);
    transition:
      border-color 0.3s ease-out,
      box-shadow 0.3s ease-out;
  }
  & svg * {
    stroke: var(--c-gray900);
  }
  &:global(.MuiOutlinedInput-multiline) {
    padding: 6px 0 7px;
  }
}

.inputRootMultiline {
  height: auto;
}

.input {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: u.spacing(2);
  padding-right: u.spacing(2);
  font-family: var(--body-font-family);
  font-size: 18px;
  &::placeholder {
    color: var(--c-gray800);
    opacity: 1;
  }
}

.startAdornment {
  padding-left: 0;
}

.endAdornment {
  padding-right: 0;
}

.focused {
  & fieldset {
    box-shadow: 0px 0px 6px rgba(24, 60, 103, 0.32);
  }
  & svg * {
    stroke: var(--c-primaryBlue);
  }
}

.disabled {
  cursor: not-allowed;
  background-color: var(--c-gray400);
}

.error {
  box-shadow: 0px 0px 6px rgba(208, 0, 0, 0.32);
  & svg * {
    stroke: var(--c-inactiveRed);
  }
}

.label {
  margin: 0;
  padding-bottom: u.spacing(1);
}

.wrapper {
  position: relative;
  padding-bottom: 0;
}

.wrapperLimit {
  padding-bottom: 27px;
}

.limit {
  position: absolute;
  bottom: 1px;
  right: 0;
  font-family: var(--body-font-family);
  font-size: 14px;
  color: var(--c-gray800);
}

.limitError {
  bottom: 3px;
}
