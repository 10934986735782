@use 'ui-kit/theme/units' as u;
@use 'ui-kit/theme/media' as mq;
@use 'ui-kit/theme/colors' as c;
@use 'sass:color';

@mixin optimizeFooterRendering() {
  // Skip children element rendering until the element is visible
  content-visibility: auto;

  // If your IDE highlights some lines here - it's the IDE's bug, the syntax is correct
  contain-intrinsic-height: auto 648px;
  @include mq.forUpToTabletLandscape() {
    contain-intrinsic-height: auto 496px;
  }
  @include mq.forUpToTablet() {
    contain-intrinsic-height: auto 1192px;
  }
  @include mq.forPhone() {
    contain-intrinsic-height: auto 1918px;
  }
}

.root {
  @include optimizeFooterRendering();

  padding: u.spacing(15, 20);
  display: flex;
  flex-direction: column;
  gap: u.spacing(5);
  @include mq.forUpToTabletLandscape {
    padding: 40px 18px;
  }
  @include mq.forUpToTablet {
    padding: u.spacing(5, 3, 10, 5);
  }
  @include mq.forDesktopMidUp {
    gap: u.spacing(8);
  }
}
.wrapper {
  display: grid;
  grid-template-columns: 1fr;
  gap: u.spacing(4);

  @include mq.forTabletUp {
    grid-template-columns: 1fr repeat(2, 1fr);
  }

  @include mq.forDesktopSmallUp {
    gap: u.spacing(3);
    grid-template-columns: 1.4fr repeat(4, 1fr);
  }
}

.contactsWrapper {
  @include mq.forTablet {
    grid-column: span 4;
  }
}
.content {
  display: grid;
  grid-template-columns: 1fr;
  gap: u.spacing(4);

  @include mq.forTabletUp {
    grid-template-columns: repeat(4, 1fr);
  }

  @include mq.forDesktopMidUp {
    gap: u.spacing(3);
    grid-template-columns: repeat(4, 1fr);
  }
}

.contentItem {
  display: flex;
  flex-direction: column;
  gap: u.spacing(2);
  @include mq.forTablet {
    grid-column: span 2;
  }
}

.title {
  margin-bottom: u.spacing(1);
  @include mq.forDesktopSmallUp {
    margin-bottom: u.spacing(2);
  }
}

.contacts {
  display: flex;
  flex-direction: column;
  gap: u.spacing(2);
  margin-top: u.spacing(2);
  margin-bottom: u.spacing(4);
}
.contactItem {
  display: flex;
  gap: u.spacing(3);
  align-items: center;
  width: fit-content;
}

.socialMedia {
  display: flex;
  gap: u.spacing(3);
}

.socialLink {
  &:hover svg > * {
    fill: color.adjust(c.$primaryBlue, $lightness: 7%);
  }
}

.keepScrollButRemoveScrollBar {
  &::-webkit-scrollbar {
    display: none;
  }
  display: flex;
  flex-direction: column;
  gap: u.spacing(2);
  overflow-y: scroll;
  max-height: 230px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.boxCnt {
  display: grid;
  gap: u.spacing(1);
  grid-template-columns: 1fr;
  @include mq.forDesktopSmallUp {
    grid-template-columns: repeat(2, auto);
  }
  @include mq.forDesktopMidUp {
    gap: u.spacing(3);
    grid-template-columns: repeat(4, auto);
  }
}

.legalItem {
  display: flex;
  gap: u.spacing(1);
  align-items: center;
}

.box {
  display: grid;
  gap: u.spacing(3);
  justify-content: space-between;
  grid-template-columns: 1fr;
  @include mq.forTabletUp {
    grid-template-columns: repeat(2, 1fr);
  }
  @include mq.forDesktopSmallUp {
    grid-template-columns: auto auto;
  }
}

.link {
  display: inline-block;
  width: fit-content;
}

.city {
  width: fit-content;
}
