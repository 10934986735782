@use 'ui-kit/theme/units' as u;
@use 'ui-kit/theme/media' as mq;

.root {
}

.content {
  display: flex;
  gap: u.spacing(1.5);
}

.highlightedTitle {
  position: relative;
}

.highlightedTitle::after {
  content: '';
  display: inline-block;
  width: 90%;
  height: 8px;
  background: rgba(236, 188, 62, 0.7);
  z-index: -1;
  border-radius: 100px;
  position: absolute;
  bottom: 4px;
  left: 4px;
  margin: 0px auto;
  @include mq.forUpToTablet {
    bottom: 0;
  }
}

.infoItem {
  display: flex;
  flex-direction: column;
  gap: u.spacing(2);
  padding: u.spacing(4);
  width: 352px;
  background-color: var(--c-white);
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  border: 1px solid var(--c-gray400);
  flex: 1;
  @include mq.forUpToTablet {
    width: 100%;
    height: auto;
    padding: u.spacing(3);
    min-width: 100%;
  }
}

.section {
  display: flex;
  gap: u.spacing(4);
  flex-wrap: wrap;
  @include mq.forUpToTablet {
    gap: u.spacing(2);
  }
  @include mq.forDesktopSmallUp {
    flex-wrap: nowrap;
  }
}

.titleWrapper {
  display: flex;
  flex-wrap: wrap;
  z-index: 1;
}

.spacing {
  margin-left: 6px;
}

.heading {
  margin-bottom: u.spacing(5);
  @include mq.forUpToTablet {
    margin-bottom: u.spacing(3);
  }
}
