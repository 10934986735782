@use 'ui-kit/theme/units' as u;
@use 'ui-kit/theme/media' as mq;

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & span {
    overflow: visible !important;
  }
}

.cardBody {
  box-sizing: border-box;
  padding: 92px 24px 24px;
  border: 1px solid var(--c-gray400);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.06);
  background-color: var(--c-white);
  border-radius: 8px;
  text-align: center;
  @include mq.forUpToTablet {
    padding: 76px 24px 24px;
  }
}

.content {
  text-align: center;
  margin-top: u.spacing(1);
  margin-bottom: u.spacing(3);
  font-weight: normal;
  height: 90px;
  @include mq.forUpToTablet {
    height: 96px;
  }
}

.avatarSpacer {
  width: 100%;
  position: relative;
  overflow: visible;
}

.avatar {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  & img {
    border-radius: 100%;
  }
}
